export const AppRoutes = {
  Root: "/",
  Users: "/users",
  Roles: "/roles",
  Events: "/events",
  Admin: "/admin",
  Account: "/account",
  GlobalSearch: "/search",
  Contacts: "/contacts",
  Marketing: "/marketing",
  Calendar: "/calendar",
  Dashboard: "/dashboard",
  Finance: "/finance",
  Public: "/public",
};

export const SystemRoutes = {
  Login: "/login",
  Logout: "/logout",
  Register: "/register",
  Authenticate: "/authenticate",
  SetupUser: "/setup-user/:token?",
  VerifyEmail: "/verify-email/:token?",
  ForgottenPassword: "/forgotten-password",
  ResetPassword: "/reset-password/:token?",
  AzureAdSignIn: "/azure-ad-signin",
};

export const AccountRoutes = {
  Profile: `${AppRoutes.Account}/profile`,
  Privacy: `${AppRoutes.Account}/privacy`,
};

export const AdminRoutes = {
  Users: `${AppRoutes.Admin}/users`,
  Roles: `${AppRoutes.Admin}/roles`,
  Lookups: `${AppRoutes.Admin}/lookups`,
  Sites: `${AppRoutes.Admin}/sites`,
  EmailTemplates: `${AppRoutes.Admin}/email-templates`,
  Integrations: `${AppRoutes.Admin}/integrations`,
};

export const IntegrationRoutes = {
  SageAuth: `${AdminRoutes.Integrations}/sage/auth`,
};

export const ContactRoutes = {
  Organisations: `${AppRoutes.Contacts}/organisations`,
  Contact: (contactId = ":contactId") => `${AppRoutes.Contacts}/${contactId}`,
  Events: (contactId = ":contactId") =>
    `${AppRoutes.Contacts}/${contactId}/events`,
  Notes: (contactId = ":contactId") =>
    `${ContactRoutes.Contact(contactId)}/notes`,
  Search: `${AppRoutes.Contacts}/search`,
  Lists: `${AppRoutes.Contacts}/lists`,
  Tags: `${AppRoutes.Contacts}/tags`,
  Import: `${AppRoutes.Contacts}/import`,
};

export const OrganisationRoutes = {
  Organisation: (organisationId = ":organisationId") =>
    `${ContactRoutes.Organisations}/${organisationId}`,
  Notes: (organisationId = ":organisationId") =>
    `${OrganisationRoutes.Organisation(organisationId)}/notes`,
  Contacts: (organisationId = ":organisationId") =>
    `${OrganisationRoutes.Organisation(organisationId)}/contacts`,
  OrganisationFunding: (organisationId = ":organisationId") =>
    `${ContactRoutes.Organisations}/${organisationId}/funding`,
  OrganisationPayments: (organisationId = ":organisationId") =>
    `${ContactRoutes.Organisations}/${organisationId}/payments`,
};

export const MarketingRoutes = {
  Campaigns: `${AppRoutes.Marketing}/campaigns`,
};

export const CampaignRoutes = {
  Campaign: (campaignId = ":campaignId") =>
    `${MarketingRoutes.Campaigns}/${campaignId}`,
  Recipients: (campaignId = ":campaignId") =>
    `${CampaignRoutes.Campaign(campaignId)}/recipients`,
  Notes: (campaignId = ":campaignId") =>
    `${CampaignRoutes.Campaign(campaignId)}/notes`,
};

export const EventRoutes = {
  Ideas: `${AppRoutes.Events}/ideas`,
  Concepts: `${AppRoutes.Events}/concepts`,
  Series: `${AppRoutes.Events}/series`,
  Pipeline: `${AppRoutes.Events}/pipeline`,
  Event: (eventId = ":eventId") => `${AppRoutes.Events}/${eventId}`,
  Overview: (eventId = ":eventId") => `${EventRoutes.Event(eventId)}/overview`,
  Programme: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/programme`,
  Resources: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/resources`,
  Participants: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/participants`,
  LiveSessions: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/live-sessions`,
  Notes: (eventId = ":eventId") => `${EventRoutes.Event(eventId)}/notes`,
  Budget: (eventId = ":eventId") => `${EventRoutes.Event(eventId)}/budget`,
  Partners: (eventId = ":eventId") => `${EventRoutes.Event(eventId)}/partners`,
  Impact: (eventId = ":eventId") => `${EventRoutes.Event(eventId)}/impact`,
  VenueInformation: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/venue-information`,
  Registration: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/registration`,
  ContactSearch: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/contact-search`,
  Meals: (eventId = ":eventId") => `${EventRoutes.Event(eventId)}/meals`,
  EmailTemplates: (eventId = ":eventId") =>
    `${EventRoutes.Event(eventId)}/email-templates`,
};

export const EventParticipantsRoutes = {
  Participant: (eventId = ":eventId", participantId = ":participantId") =>
    `${EventRoutes.Participants(eventId)}/${participantId}`,
};

export const EventProgrammeParticipantsRoutes = {
  Participant: (eventId = ":eventId", participantId = ":participantId") =>
    `${EventRoutes.Programme(eventId)}/participants/${participantId}`,
};

export const IdeaRoutes = {
  Idea: (ideaId = ":ideaId") => `${EventRoutes.Ideas}/${ideaId}`,
  Participants: (ideaId = ":ideaId") =>
    `${IdeaRoutes.Idea(ideaId)}/participants`,
  Notes: (ideaId = ":ideaId") => `${IdeaRoutes.Idea(ideaId)}/notes`,
};

export const ConceptRoutes = {
  Concept: (conceptId = ":conceptId") => `${EventRoutes.Concepts}/${conceptId}`,
  Programme: (conceptId = ":conceptId") =>
    `${ConceptRoutes.Concept(conceptId)}/programme`,
  Resources: (conceptId = ":conceptId") =>
    `${ConceptRoutes.Concept(conceptId)}/resources`,
  Participants: (conceptId = ":conceptId") =>
    `${ConceptRoutes.Concept(conceptId)}/participants`,
  Budget: (conceptId = ":conceptId") =>
    `${ConceptRoutes.Concept(conceptId)}/budget`,
  Partners: (conceptId = ":conceptId") =>
    `${ConceptRoutes.Concept(conceptId)}/partners`,
  Notes: (conceptId = ":conceptId") =>
    `${ConceptRoutes.Concept(conceptId)}/notes`,
  ContactSearch: (conceptId = ":conceptId") =>
    `${ConceptRoutes.Concept(conceptId)}/contact-search`,
};

export const ConceptParticipantsRoutes = {
  Participant: (conceptId = ":conceptId", participantId = ":participantId") =>
    `${ConceptRoutes.Participants(conceptId)}/${participantId}`,
};

export const SeriesRoutes = {
  Series: (seriesId = ":seriesId") => `${EventRoutes.Series}/${seriesId}`,
  Notes: (seriesId = ":seriesId") => `${SeriesRoutes.Series(seriesId)}/notes`,
  Funding: (seriesId = ":seriesId") =>
    `${SeriesRoutes.Series(seriesId)}/funding`,
};

export const DashboardRoutes = {
  Events: `${AppRoutes.Dashboard}/events`,
  Participants: `${AppRoutes.Dashboard}/participants`,
  Financial: `${AppRoutes.Dashboard}/financial`,
  BusinessDevelopment: `${AppRoutes.Dashboard}/business-development`,
  ImpactsAndEvidence: `${AppRoutes.Dashboard}/impacts-and-evidence`,
};

export const FinanceRoutes = {
  Departments: `${AppRoutes.Finance}/departments`,
  PurchaseOrderApprovalLevels: `${AppRoutes.Finance}/po-approval-levels`,
  PurchaseOrders: `${AppRoutes.Finance}/purchase-orders`,
  EventPurchaseOrderApprovers: `${AppRoutes.Finance}/event-po-approvers`,
};

export const DepartmentRoutes = {
  Department: (departmentId = ":departmentId") =>
    `${FinanceRoutes.Departments}/${departmentId}`,
  PurchaseOrderApprovers: (departmentId = ":departmentId") =>
    `${DepartmentRoutes.Department(departmentId)}/purchase-order-approvers`,
};

export const PurchaseOrderRoutes = {
  PurchaseOrder: (purchaseOrderId = ":purchaseOrderId") =>
    `${FinanceRoutes.PurchaseOrders}/${purchaseOrderId}`,
  Notes: (purchaseOrderId = ":purchaseOrderId") =>
    `${FinanceRoutes.PurchaseOrders}/${purchaseOrderId}/notes`,
};

export const PublicRoutes = {
  Resource: (token = ":token") => `${AppRoutes.Public}/resources/${token}`,
  DataRetention: (token = ":token") =>
    `${AppRoutes.Public}/data-retention/${token}`,
};
