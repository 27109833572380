import React, { useEffect, useState } from "react";
import {
  LookupDropdown,
  useLookup,
  useFormField,
  connectForm,
  Field,
  FormUtils,
  FormValidators,
  lookupRequest,
} from "@redriver/cinnamon";
import { Dropdown as SDropdown, Icon, Form as SForm } from "semantic-ui-react";
import classNames from "classnames";
import PropTypes from "prop-types";

const OutsideMultiSelect = ({
  value,
  onChange,
  onBlur,
  errors,
  showErrors,
  allErrors,
  animateErrors,
  disabled,
  label,
  width,
  fluid,
  required,
  passThruProps,
  className,
  children,
  options,
  lookup,
  lookupParams,
  style,
}) => {
  const [selected, setSelected] = useState(
    Array.isArray(value)
      ? value.map((x) => ({ value: x.value, text: x.name }))
      : []
  );

  const onChangeSelection = (next) => {
    setSelected(next);
    onChange(
      next.map((x) => ({
        value: x.value,
        name: x.text,
      }))
    );
  };

  const [availableOptions, setAvailableOptions] = useState(options || []);
  const [{ loading, response }] = useLookup(lookup, lookupParams);

  const displayOptions = availableOptions
    .filter((x) => (selected || []).every((s) => s.value != x.value))
    .map(({ value, text }) => ({ value, text })); // strip out any other fields from the response

  useEffect(() => {
    if (response) {
      setAvailableOptions(response);
    }
  }, [response]);

  // NOTE: manually using options is untested. when that first use case comes up this useEffect will need to be checked that it is working
  useEffect(() => {
    if (!lookup) {
      setAvailableOptions(options || []);
    }
  }, [options, lookup]);

  const otherProps = FormUtils.omitProps(
    passThruProps,
    Object.keys(OutsideMultiSelect.propTypes)
  );

  return (
    <div
      className={classNames("outside-multiselect", { disabled }, className)}
      style={style}
    >
      <Field
        required={required}
        disabled={disabled}
        width={width}
        fluid={fluid}
        label={label}
        errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        <SDropdown
          {...otherProps}
          disabled={disabled}
          search
          selection
          allowAdditions
          value={null}
          onChange={(_, { value, options, ...other }) => {
            const option = options.find((x) => x.value == value);
            if (option) {
              onChangeSelection([...(selected || []), option]);
            }
          }}
          onAddItem={(_, { value }) => {
            if (selected.every((x) => x.value != value)) {
              const option = {
                value,
                text: value,
                isNew: true,
              };
              onChangeSelection([...(selected || []), option]);
            }
          }}
          loading={loading}
          options={displayOptions}
          selectOnBlur={false}
        />

        <ul className="tags">
          {(selected || []).map((x) => (
            <li key={x.value} className="tag">
              {x.text}
              <Icon
                name="close"
                style={{
                  marginLeft: "0.25em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const next = (selected || []).filter(
                    (y) => y.value != x.value
                  );
                  onChangeSelection(next);
                }}
              />
            </li>
          ))}
        </ul>
      </Field>
    </div>
  );
};

OutsideMultiSelect.propTypes = {
  // -------------------
  // field props
  // -------------------

  /**
   * Label text to display alongside the field
   */
  label: PropTypes.node,
  /**
   * Width of the field in approximate number of characters, or a valid CSS width
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Whether the width of the field should automatically fill all available space
   */
  fluid: PropTypes.bool,

  /**
   * Additional classes for styling
   */
  className: PropTypes.string,

  options: PropTypes.array,

  // -------------------
  // validator props
  // -------------------

  /**
   * Whether this field should be mandatory to completing the form
   */
  required: PropTypes.bool,
  /**
   * Override the default error message for required fields
   */
  requiredError: PropTypes.string,

  // -------------------
  // connectForm props
  // -------------------

  /**
   * Name of this field, and the form data key against which the value will be stored
   */
  field: PropTypes.string.isRequired,
  /**
   * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
   */
  showErrors: PropTypes.bool,
  /**
   * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
   */
  allErrors: PropTypes.bool,
  /**
   * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
   */
  animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  /**
   * Whether to disable the field, if not specified then inherits from the parent form or fields
   */
  disabled: PropTypes.bool,
  /**
   * Whether the field should be read-only, if not specified then inherits from the parent form or fields
   * @ignore
   */
  readOnly: PropTypes.bool,
  /**
   * Additional error messages that should be displayed before validator error messages
   */
  customErrors: PropTypes.arrayOf(PropTypes.string),
  /**
   * Function that will be run every time the field changes to perform additional validation
   * Resulting errors can be passed to customErrors
   */
  customValidator: PropTypes.func,
  /**
   * List of other field names that should be re-validated when this field changes
   */
  notifiedFields: PropTypes.arrayOf(PropTypes.string),
  /**
   * Current value of the field (supplied by the form connection)
   * @ignore
   */
  value: PropTypes.array,
  /**
   * Callback when the field has changed (supplied by the form connection)
   * @ignore
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Array of error messages to display on the field (supplied by the form connection)
   * @ignore
   */
  errors: PropTypes.arrayOf(PropTypes.string),
  /**
   * Props excluding those from the form connection (supplied by the form connection)
   * @ignore
   */
  passThruProps: PropTypes.object,
  /**
   * The current state of the form relative to this field (supplied by the form connection)
   * @ignore
   */
  formState: PropTypes.object,
};

OutsideMultiSelect.defaultProps = {
  onChange: () => {},
  label: "",
};

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [FormValidators.requiredField(false)],
})(OutsideMultiSelect);
