import React from "react";
import classNames from "classnames";
import { Segment } from "semantic-ui-react";

const TablePanel = ({
  className,
  children,
  compact = false,
  noBorder = false,
}) => (
  <Segment
    className={classNames(
      "table-panel",
      {
        compact,
        "no-border": noBorder,
      },
      className
    )}
  >
    {children}
  </Segment>
);

export default TablePanel;
