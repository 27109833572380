import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { AppRoutes, ContactRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";
import ContactsIcon from "assets/icons/contacts.svg";
import OrganisationsIcon from "assets/icons/organisations.svg";
import ImportIcon from "assets/icons/import.svg";
import SearchIcon from "assets/icons/search.svg";
import ListIcon from "assets/icons/list.svg";
import TagIcon from "assets/icons/tag.svg";

const ContactsTabMenuItems = ({ showIcons, organisationSubMenu }) => {
  const canViewContacts = usePermission(Targets.Contact, Actions.View);
  const canViewOrganisations = usePermission(
    Targets.Organisation,
    Actions.View
  );
  const canViewContactSearch = usePermission(
    Targets.ContactSearch,
    Actions.View
  );
  const canViewLists = usePermission(Targets.ContactSearchList, Actions.View);
  const canViewTags = usePermission(Targets.ContactSearchTag, Actions.View);
  const canViewContactImport = usePermission(Targets.Contact, Actions.Import);

  return (
    <React.Fragment>
      {canViewContacts && (
        <NavMenu.Item link={AppRoutes.Contacts} exact>
          {showIcons && <img src={ContactsIcon} />}
          <span>Contacts</span>
        </NavMenu.Item>
      )}
      {canViewOrganisations && (
        <React.Fragment>
          <NavMenu.Item link={ContactRoutes.Organisations}>
            {showIcons && <img src={OrganisationsIcon} />}
            <span>Organisations</span>
          </NavMenu.Item>
          {organisationSubMenu}
        </React.Fragment>
      )}
      {canViewContactSearch && (
        <NavMenu.Item link={ContactRoutes.Search}>
          {showIcons && <img src={SearchIcon} />}
          <span>Advanced Contact Search</span>
        </NavMenu.Item>
      )}
      {canViewLists && (
        <NavMenu.Item link={ContactRoutes.Lists}>
          {showIcons && <img src={ListIcon} />}
          <span>Search Lists</span>
        </NavMenu.Item>
      )}
      {canViewTags && (
        <NavMenu.Item link={ContactRoutes.Tags}>
          {showIcons && <img src={TagIcon} />}
          <span>Search Tags</span>
        </NavMenu.Item>
      )}
      {canViewContactImport && (
        <NavMenu.Item link={ContactRoutes.Import}>
          {showIcons && <img src={ImportIcon} />}
          <span>Contact Import</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default ContactsTabMenuItems;
