export const Actions = {
  View: "v",
  Create: "c",
  Edit: "e",
  Delete: "d",
  Approve: "a",
  Publish: "p",
  Reset: "r",
  Convert: "cv",
  Revert: "rv",
  Import: "im",
  Export: "ex",
  Allocate: "al",
  Invite: "i",
  Register: "r",
  Suspend: "s",
  Cancel: "cl",
  Download: "dl",
  Anonymise: "an",
  Merge: "m",
  Assign: "as",
};

export const Targets = {
  User: "u",
  UserMfa: "umfa",
  UserNote: "un",
  Role: "r",
  Event: "e",
  EventNote: "en",
  EventBudget: "eb",
  EventPartner: "ep",
  EventQuickLinks: "eql",
  EventResourceExpiry: "ere",
  EventAlert: "ea",
  EventOdaFunding: "eof",
  EventFunding: "ef",
  EventContact: "ec",
  EventSurvey: "es",
  EventEvidenceQuickLinks: "eeql",
  EventObjective: "eo",
  EventVenueInformation: "evi",
  EventParticipant: "ept",
  EventRegistrationTemplate: "ert",
  EventMeal: "em",
  EventEmailTemplate: "eet",
  EventEvidence: "ee",
  Participant: "p",
  ParticipantType: "pt",
  ParticipantProfile: "pp",
  ParticipantProfilePrivacy: "ppp",
  ParticipantContact: "pc",
  ParticipantNote: "pn",
  ParticipantFee: "pf",
  ParticipantFeeInvoice: "pfi",
  ParticipantPayment: "ppay",
  ParticipantAddress: "pa",
  ParticipantTravelAndAccommodation: "pta",
  ParticipantRegistration: "pr",
  ParticipantAccommodation: "pac",
  ParticipantGuestlineStatus: "pgs",
  PendingUser: "pu",

  // concept participants
  ConceptParticipant: "cpnt",
  ConceptParticipantProfile: "cpp",
  ConceptParticipantProfilePrivacy: "cppp",
  ConceptParticipantContact: "cpc",
  ConceptParticipantNote: "cpn",
  ConceptParticipantTravelAndAccommodation: "cpta",
  ConceptParticipantGuestlineStatus: "cpgs",

  Resource: "rc",
  ResourceCategory: "rct",
  ResourceComment: "rcm",
  Programme: "pg",
  ProgrammeTheme: "pgt",
  ProgrammeSubTheme: "pgst",
  Idea: "i",
  IdeaQuickLinks: "iql",
  IdeaNote: "in",
  IdeaContact: "ic",
  Concept: "c",
  ConceptNote: "cn",
  ConceptBudget: "cb",
  ConceptPartner: "cp",
  ConceptQuickLinks: "cql",
  ConceptOdaFunding: "cof",
  ConceptFunding: "cf",
  ConceptContact: "cc",
  GlobalSearch: "gs",
  Calendar: "cal",
  CalendarAdhocDates: "cald",
  Series: "s",
  SeriesPartner: "sp",
  SeriesQuickLinks: "sql",
  SeriesNote: "sn",
  Site: "si",
  SiteNote: "sin",
  Pipeline: "pl",
  EmailTemplate: "et",

  // participant event permissions
  ParticipantEvent: "pe",
  ParticipantEventResourceComment: "percm",
  ParticipantEventParticipant: "pep",
  ParticipantBiography: "pb",

  // contact permissions
  Contact: "ct",
  ContactNote: "ctn",
  ContactEvent: "ce",
  ContactFutureEventProfile: "cfep",
  ContactYearOfBirth: "cyob",
  ContactGdprAcceptance: "cga",
  ContactParticipant: "cpt",
  Organisation: "o",
  OrganisationType: "ot",
  OrganisationNote: "on",
  OrganisationContact: "oc",

  // contact search permissions
  ContactSearch: "cs",
  ContactSearchSavedSearch: "csss",
  PublicContactSearchSavedSearch: "pcsss",
  OtherUsersContactSearchSavedSearch: "ocsss",
  EventContactSearch: "ecs",
  EventContactSearchSavedSearch: "ecsss",
  PublicEventContactSearchSavedSearch: "pecsss",
  OtherUsersEventContactSearchSavedSearch: "oecsss",
  EventContactSearchParticipant: "ecsp",
  ConceptContactSearch: "ccs",
  ConceptContactSearchSavedSearch: "ccsss",
  PublicConceptContactSearchSavedSearch: "pccsss",
  OtherUsersConceptContactSearchSavedSearch: "occsss",
  ConceptContactSearchParticipant: "ccsp",

  // contact search list/tag permissions
  ContactSearchList: "csl",
  PublicContactSearchList: "pcsl",
  OtherUsersContactSearchList: "oucsl",
  ContactSearchTag: "cst",
  PublicContactSearchTag: "pcst",
  OtherUsersContactSearchTag: "oucst",
  ContactTag: "ctg",
  PublicContactTag: "pctg",
  OrganisationTag: "otg",
  PublicOrganisationTag: "potg",

  // marketing permissions
  Campaign: "cm",
  CampaignNote: "cmn",

  // eyes only
  EyesOnlyEvent: "eoe",
  EyesOnlyConcept: "eoc",

  // funding permissions
  OrganisationFunding: "of",
  OrganisationFundingPaymentSchedule: "ofps",
  OrganisationFundingPaymentScheduleInvoice: "ofpsi",
  OrganisationFundingAddress: "ofa",
  OrganisationFundingPayment: "ofp",
  OrganisationOdaFunder: "oof",
  SeriesFunding: "sf",
  OrganisationFundingContact: "ofc",
  SeriesFundingContact: "sfc",
  EventFundingContact: "efc",
  ConceptFundingContact: "cfc",

  // alert permissions
  NewOrganisationAlert: "oa",

  // dashboard permissions
  EventsDashboard: "ed",
  ParticipantsDashboard: "pd",
  FinancialDashboard: "fd",
  BusinessDevelopmentDashboard: "bdd",
  ImpactsAndEvidenceDashboard: "ied",

  // department
  Department: "dp",
  DepartmentNominalCodes: "dpnc",
  PurchaseOrderApprover: "dppoa",
  AllDepartment: "adp",

  // purchase orders
  PurchaseOrder: "po",
  PurchaseOrderAccruals: "poa",
  DepartmentPurchaseOrder: "dpo",
  EventPurchaseOrder: "epo",
  OtherUsersPurchaseOrder: "oupo",
  ApprovedPurchaseOrder: "apo",
  PurchaseOrderApprovalLevel: "poal",
  EventPurchaseOrderApprover: "epoa",
  PurchaseOrderNote: "pon",
  SupplierInvoice: "siv",
  SupplierInvoiceDocument: "sid",

  // integrations
  SageIntegration: "sgi",
};
