import React from "react";
import classNames from "classnames";
import { Button } from "semantic-ui-react";
import { Modal } from "@redriver/cinnamon";

const TableModal = ({ header, onCancel, className, ...props }) => (
  <Modal.Information
    {...props}
    header={
      <React.Fragment>
        {header}
        <Button
          basic
          icon="close"
          className="close-modal"
          onClick={(e) => {
            e.stopPropagation();
            if (onCancel) onCancel(e);
          }}
        />
      </React.Fragment>
    }
    className={classNames("table-modal", className)}
    onCancel={onCancel}
  />
);

export default TableModal;
