import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ColourSquareWrapper = styled.div.attrs((props) => ({
  style: {
    backgroundColor: props.colour,
  },
}))`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 20px;
  height: 20px;
  margin-left: 0.5em;
`;

const ColourSquareIcon = ({ ...props }) => {
  return <ColourSquareWrapper {...props} />;
};

ColourSquareIcon.propTypes = {
  colour: PropTypes.string,
};

export default ColourSquareIcon;
