import React from "react";
import { Table } from "@redriver/cinnamon";
import { ContentHeader } from "components/containers";
import { EventRoutes } from "constants/routes";
import { usePermission } from "components/auth";
import { Link } from "react-router-dom";
import { Targets, Actions } from "constants/permissions";

const OverviewEventsTable = ({ title, data }) => {
  const hasViewEventsPermission = usePermission(Targets.Event, Actions.View);

  return (
    <div className="participant-overview-table">
      <ContentHeader title={title}></ContentHeader>
      <Table data={data} dataKey="eventId" emptyMessage="No events">
        <Table.Column
          title="Event Code"
          width="15%"
          render={(item) =>
            hasViewEventsPermission ? (
              <Link to={EventRoutes.Event(item.eventId)}>
                {item.eventCode ?? ""}
              </Link>
            ) : (
              item.eventCode ?? ""
            )
          }
        />
        <Table.Column field="eventName" title="Name" width="25%" />
        <Table.Column
          title="Participant Type"
          width="20%"
          render={(item) => (item.participantTypes ?? []).join(", ") || "-"}
        />
        <Table.Column title="Status" field="participantStatus" width="15%" />
        <Table.Column
          title="Unable Reason"
          render={(item) => item.unableReason || "-"}
          width="15%"
        />
        <Table.Column
          title="Organisation"
          width="15%"
          render={(item) => item.participantOrganisation || "-"}
        />
        <Table.Column
          title="Position"
          width="15%"
          render={(item) => item.participantJobTitle || "-"}
        />
      </Table>
    </div>
  );
};

export default OverviewEventsTable;
