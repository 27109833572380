import React from "react";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import {
  connectForm,
  Field,
  FormUtils,
  FormValidators,
} from "@redriver/cinnamon";

class ImageCropper extends React.Component {
  static propTypes = {
    // -------------------
    // field props
    // -------------------

    /**
     * Label content to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,
    /**
     * The image to be cropped
     */
    src: PropTypes.string,
    /**
     * File object to be cropped (alternative to using src)
     */
    file: PropTypes.object,
    /**
     * The maximum allowed aspect ratio
     */
    maxAspect: PropTypes.number,
    /**
     * The minimum allowed aspect ratio
     */
    minAspect: PropTypes.number,

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run every time the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    onChange: () => {},
    label: "",
    initialCrop: {},
  };

  _imageRef = null;

  constructor(props) {
    super(props);
    this.state = {
      src: props.src,
    };
  }

  componentDidMount() {
    const { file } = this.props;
    if (file) this.readFile(file);
  }

  componentDidUpdate(prevProps) {
    const { src, file } = this.props;
    if (file && file !== prevProps.file) {
      this.readFile(file);
    } else if (src !== prevProps.src) {
      this.setState({ src });
    }
  }

  readFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      this.setState({ src: reader.result });
    });
    reader.readAsDataURL(file);
  };

  onImageLoaded = (image) => {
    this._imageRef = image;
  };

  onChange = (newCrop) => {
    const { minAspect, maxAspect } = this.props;
    let targetAspect = undefined;
    const crop = { ...newCrop };

    if (maxAspect && crop.width / crop.height > maxAspect) {
      targetAspect = maxAspect;
    } else if (minAspect && crop.width / crop.height < minAspect) {
      targetAspect = minAspect;
    }

    if (targetAspect !== undefined) {
      if (crop.height !== this.state.crop.height) {
        const newHeight = crop.width / targetAspect;
        const heightChange = newHeight - crop.height;
        crop.height = newHeight;
        if (crop.y !== this.state.crop.y) crop.y -= heightChange;
      } else {
        const newWidth = crop.height * targetAspect;
        const widthChange = newWidth - crop.width;
        crop.width = newWidth;
        if (crop.x !== this.state.crop.x) crop.x -= widthChange;
      }
    }

    this.setState({ crop });
  };

  onComplete = (newCrop) => {
    // when required field always show crop selection
    if (
      this._imageRef &&
      this.props.required &&
      (newCrop.width === 0 || newCrop.height === 0)
    ) {
      newCrop.unit = "px";
      newCrop.x = 0;
      newCrop.y = 0;
      newCrop.width = this._imageRef.width;
      newCrop.height = this._imageRef.height;

      const { maxAspect, minAspect } = this.props;
      const aspectRatio = newCrop.width / newCrop.height;

      if (maxAspect && aspectRatio > maxAspect) {
        const newWidth = newCrop.height * maxAspect;
        newCrop.x += (newCrop.width - newWidth) * 0.5;
        newCrop.width = newWidth;
      } else if (minAspect && aspectRatio < minAspect) {
        const newHeight = newCrop.width / minAspect;
        newCrop.y += (newCrop.height - newHeight) * 0.5;
        newCrop.height = newHeight;
      }

      this.setState({ crop: newCrop });
    }

    const value = { ...newCrop };

    // auto-scale pixel units to original image size in case rendered differently
    if (this._imageRef && value.unit === "px") {
      const scaleX = this._imageRef.naturalWidth / this._imageRef.width;
      const scaleY = this._imageRef.naturalHeight / this._imageRef.height;

      if (value.x !== undefined) value.x *= scaleX;
      if (value.y !== undefined) value.y *= scaleY;
      if (value.width !== undefined) value.width *= scaleX;
      if (value.height !== undefined) value.height *= scaleY;
    }

    this.props.onChange(value);
  };

  render() {
    const {
      value,
      onChange,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      width,
      fluid,
      required,
      passThruProps,
    } = this.props;

    const otherProps = FormUtils.omitProps(
      passThruProps,
      Object.keys(ImageCropper.propTypes)
    );

    return (
      <Field
        required={required}
        disabled={disabled}
        width={width}
        fluid={fluid}
        label={label}
        errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
        className="image-cropper"
      >
        <ReactCrop
          minHeight={1}
          minWidth={1}
          {...otherProps}
          src={this.state.src}
          crop={this.state.crop}
          onImageLoaded={this.onImageLoaded}
          onChange={this.onChange}
          onComplete={this.onComplete}
        />
      </Field>
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [FormValidators.requiredField(false)],
})(ImageCropper);
