import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { MarketingRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { usePermission } from "components/auth";
import CampaignIcon from "assets/icons/campaign.svg";

const MarketingTabMenuItems = ({ showIcons }) => {
  const canViewCampaigns = usePermission(Targets.Campaign, Actions.View);

  return (
    <React.Fragment>
      {canViewCampaigns && (
        <NavMenu.Item link={MarketingRoutes.Campaigns}>
          {showIcons && <img src={CampaignIcon} />}
          <span>Campaigns</span>
        </NavMenu.Item>
      )}
    </React.Fragment>
  );
};

export default MarketingTabMenuItems;
