import React from "react";
import { registerLookup } from "@redriver/cinnamon";
import { OrganisationLookupItem } from "components/organisations";
import * as actions from "./actions";

export const eventStatusLookup = registerLookup(
  "eventStatus",
  actions.getEventStatuses
);

export const eventFormatLookup = registerLookup(
  "eventFormat",
  actions.getEventFormats
);

export const eventLocationTypeLookup = registerLookup(
  "eventLocationType",
  actions.getEventLocationTypes
);

export const eventPartnerTypeLookup = registerLookup(
  "eventPartnerType",
  actions.getEventPartnerTypes
);

export const eventPartnerPrivacyLookup = registerLookup(
  "eventPartnerPrivacy",
  actions.getEventPartnerPrivacy
);

export const eventDetailsLookup = registerLookup(
  "eventDetailsLookup",
  actions.getEventDetails,
  {
    cacheExpiry: 5000,
  }
);

export const noteTypeLookup = registerLookup("noteType", actions.getNoteTypes);

export const programmeThemeLookup = registerLookup(
  "programmeTheme",
  actions.getProgrammeThemes,
  { cache: false }
);

export const programmeContentTypeLookup = registerLookup(
  "programmeContentType",
  actions.getProgrammeContentTypes
);

export const participantTypeLookup = registerLookup(
  "participantType",
  actions.getParticipantTypes,
  {
    cache: false,
  }
);

export const participationStatusLookup = registerLookup(
  "participationStatus",
  actions.getParticipationStatuses
);

export const resourceTypeLookup = registerLookup(
  "resourceType",
  actions.getResourceTypes
);

export const resourceScopeLookup = registerLookup(
  "resourceScope",
  actions.getResourceScopes
);

export const resourceParticipantAccessLookup = registerLookup(
  "resourceParticipantAccess",
  actions.getResourceParticipantAccess
);

export const participantEventDetailsLookup = registerLookup(
  "participantEventDetailsLookup",
  actions.getParticipantEventDetails,
  {
    cacheExpiry: 5000,
  }
);

export const socialMediaTypesLookup = registerLookup(
  "socialMediaTypes",
  actions.getSocialMediaTypes
);

export const eventCategoriesLookup = registerLookup(
  "eventCategories",
  actions.getEventCategories
);

export const ideaDetailsLookup = registerLookup(
  "ideaDetailsLookup",
  actions.getIdeaDetails,
  {
    cacheExpiry: 5000,
  }
);

export const ideaStatusLookup = registerLookup(
  "ideaStatus",
  actions.getIdeaStatuses
);

export const ideaTeamMembersLookup = registerLookup(
  "ideaTeamMembersLookup",
  actions.getIdeaTeamMembers,
  { cacheLimit: 1 }
);

export const eventTeamMembersLookup = registerLookup(
  "eventTeamMembersLookup",
  actions.getEventTeamMembers,
  { cacheLimit: 1 }
);

export const conceptStatusLookup = registerLookup(
  "conceptStatus",
  actions.getConceptStatuses
);

export const seriesDetailsLookup = registerLookup(
  "seriesDetailsLookup",
  actions.getSeriesDetails,
  {
    cacheExpiry: 5000,
  }
);

export const organisationDetailsLookup = registerLookup(
  "organisationDetailsLookup",
  actions.getOrganisationDetails,
  {
    cacheExpiry: 5000,
  }
);

export const myProfileImagesLookup = registerLookup(
  "myProfileImages",
  actions.getMyProfileImages
);

export const organisationTypeLookup = registerLookup(
  "organisationType",
  actions.getOrganisationTypes,
  {
    cache: false,
  }
);

export const conceptDetailsLookup = registerLookup(
  "conceptDetailsLookup",
  actions.getConceptDetails,
  {
    cacheExpiry: 5000,
  }
);

export const regionsLookup = registerLookup(
  "regionsLookup",
  actions.getRegions
);
export const countriesLookup = registerLookup(
  "countriesLookup",
  actions.getCountries
);

export const genderLookup = registerLookup("genderLookup", actions.getGenders);

export const contactDetailsLookup = registerLookup(
  "contactDetailsLookup",
  actions.getContactDetails,
  {
    cacheExpiry: 5000,
  }
);

export const contactPreferencesLookup = registerLookup(
  "contactPreferencesLookup",
  actions.getContactPreferences
);

export const siteTypesLookup = registerLookup(
  "siteTypes",
  actions.getSiteTypes
);
export const prioritiesLookup = registerLookup(
  "prioritiesLookup",
  actions.getPriorities
);
export const onlineOfflineStatusesLookup = registerLookup(
  "onlineOfflineStatusesLookup",
  actions.getOnlineOfflineStatuses
);

export const fundingStatusesLookup = registerLookup(
  "fundingStatusesLookup",
  actions.getFundingStatuses
);

export const participantFeeTypeLookup = registerLookup(
  "participantFeeTypeLookup",
  actions.getParticipantFeeTypes
);

export const participantPaymentMethodLookup = registerLookup(
  "participantPaymentMethodLookup",
  actions.getParticipantPaymentMethods
);

export const quarterLookup = registerLookup(
  "quarterLookup",
  actions.getQuarters
);

export const odaEligibilityLookup = registerLookup(
  "odaEligibilityLookup",
  actions.getOdaEligibility
);
export const sectorsLookup = registerLookup("getSectors", actions.getSectors);

export const programmeSubThemesLookup = registerLookup(
  "programmeSubThemesLookup",
  actions.getProgrammeSubThemes
);

export const friendsOfWiltonParkLookup = registerLookup(
  "friendsOfWiltonParkLookup",
  actions.getFriendsOfWiltonPark
);

export const participantUnableReasonsLookup = registerLookup(
  "participantUnableReasonsLookup",
  actions.getParticipantUnableReasons
);

export const participantRepresentationsLookup = registerLookup(
  "participantRepresentationsLookup",
  actions.getParticipantRepresentations
);

export const participantAttendanceCategoriesLookup = registerLookup(
  "participantAttendanceCategoriesLookup",
  actions.getParticipantAttendanceCategories
);

export const contactStatusLookup = registerLookup(
  "contactStatusLookup",
  actions.getContactStatuses
);

export const organisationStatusLookup = registerLookup(
  "organisationStatusLookup",
  actions.getOrganisationStatuses
);

export const fundingPaymentScheduleStatusesLookup = registerLookup(
  "fundingPaymentScheduleStatusesLookup",
  actions.GetFundingPaymentScheduleStatuses
);

export const availableOrganisationsLookup = registerLookup(
  "availableOrganisationsLookup",
  actions.getAvailableOrganisations,
  {
    transform: (data) => {
      return data.results.map((x) => ({
        text: x.text,
        value: x.value,
        content: (
          <OrganisationLookupItem
            name={x.text}
            townOrCity={x.townOrCity}
            country={x.country}
          />
        ),
      }));
    },
  }
);

export const departmentDetailsLookup = registerLookup(
  "departmentDetailsLookup",
  actions.getDepartmentDetails,
  {
    cacheExpiry: 5000,
  }
);

export const purchaseOrderCostTypesLookup = registerLookup(
  "purchaseOrderCostTypesLookup",
  actions.getPurchaseOrderCostTypes
);

export const timeRelativityLookup = registerLookup(
  "timeRelativityLookup",
  actions.getTimeRelativity
);

export const eventSecurityLevelsLookup = registerLookup(
  "eventSecurityLevelsLookup",
  actions.getEventSecurityLevels
);

export const availableOriginatingEventsLookup = registerLookup(
  "availableOriginatingEventsLookup",
  actions.getAvailableOriginatingEvents
);

export const purchaseOrderDetailsLookup = registerLookup(
  "purchaseOrderDetailsLookup",
  actions.getPurchaseOrderDetails,
  {
    cacheExpiry: 5000,
  }
);

export const campaignDetailsLookup = registerLookup(
  "campaignDetailsLookup",
  actions.getCampaignDetails,
  {
    cacheExpiry: 5000,
  }
);

export const campaignStatusLookup = registerLookup(
  "campaignStatusLookup",
  actions.getCampaignStatuses
);
