import { Format } from "@redriver/cinnamon";
import React from "react";
import { Popup } from "semantic-ui-react";

const GdprStatusIcon = ({
  totalInteractions,
  lastInteractionUtc,
  removalRequested = false,
}) => {
  if (!totalInteractions) return null;

  const colour = removalRequested
    ? "#0b0c10" // scss $off-black
    : totalInteractions > 3
    ? "#db2828" // scss $red
    : totalInteractions > 2
    ? "#eb5a46" // scss $orange
    : totalInteractions > 1
    ? "#eaab00" // scss $highlight-yellow
    : "#f0c756"; // scss $pale-yellow

  return (
    <Popup
      trigger={
        <svg
          className="gdpr-status-icon"
          fill={colour}
          height="16px"
          width="16px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 507.176 507.176"
        >
          <g>
            <g>
              <path
                d="M449.712,32.828H61.416C28.644,32.828,0,60.004,0,92.776v221.888c0,32.772,28.64,58.916,61.416,58.916h238.736
			l99.22,99.476c0.752,0.756,1.768,1.292,2.796,1.292c0.512,0,0.036-0.032,0.532-0.24c1.48-0.616,1.456-2,1.456-3.596V373.58h45.56
			c32.776,0,57.46-26.144,57.46-58.916V92.776C507.172,60.004,482.492,32.828,449.712,32.828z M233.776,117.456
			c0-13.54,8.724-24.56,21.788-24.56c13.076,0,21.796,11.02,21.796,24.56v109.116c0,13.548-8.724,24.568-21.796,24.568
			c-13.068,0-21.788-11.02-21.788-24.568V117.456z M255.564,314.072c-12.968,0-23.516-10.556-23.516-23.516
			c0-12.976,10.548-23.52,23.516-23.52c12.968,0,23.516,10.544,23.516,23.52C279.08,303.516,268.532,314.072,255.564,314.072z"
              />
            </g>
          </g>
        </svg>
      }
      offset={[-10, 0]}
      content={
        <div>
          {removalRequested
            ? "Contact has requested to remove their data"
            : totalInteractions > 3
            ? "Final GDPR email sent"
            : `${totalInteractions} GDPR email${
                totalInteractions === 1 ? "" : "s"
              } sent`}
          <Format.Date value={lastInteractionUtc} format=" (DD/MM/YYYY)" />
        </div>
      }
    />
  );
};

export default GdprStatusIcon;
