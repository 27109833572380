import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image, Icon, Menu } from "semantic-ui-react";
import {
  NavMenu,
  ActionMenu,
  SidePanel,
  useResponsive,
} from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, AccountRoutes, SystemRoutes } from "constants/routes";
import Logo from "assets/logos/logo-blue.svg";
import {
  EventsTabMenuItems,
  ContactsTabMenuItems,
  MarketingTabMenuItems,
  AdminTabsMenuItems,
  DashboardTabMenuItems,
  FinanceTabMenuItems,
} from "components/menus";
import { ScreenBreakpointNames } from "constants/screenBreakpoints";
import EventSubMenu from "./EventSubMenu";
import ParticipantEventSubMenu from "./ParticipantEventSubMenu";
import GlobalSearchNavItem from "./GlobalSearchNavItem";
import AlertsNavItem from "./AlertsNavItem";
import AccountNavItem from "./AccountNavItem";
import IdeaSubMenu from "./IdeaSubMenu";
import OrganisationSubMenu from "./OrganisationSubMenu";
import SeriesSubMenu from "./SeriesSubMenu";
import ConceptSubMenu from "./ConceptSubMenu";
import { canCloseSidePanel } from "modules/helpers";

const WiltonParkMenu = () => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const { screenSize } = useResponsive();
  const isLargeScreen = screenSize === ScreenBreakpointNames.Desktop;
  const isMobile = screenSize === ScreenBreakpointNames.Mobile;

  const mainMenuItems = (
    <React.Fragment>
      <PermissionCheck action={Actions.View} target={Targets.ParticipantEvent}>
        <NavMenu.Item link={AppRoutes.Events}>My Events</NavMenu.Item>
        {isMobile && <ParticipantEventSubMenu />}
      </PermissionCheck>

      <PermissionCheck
        action={Actions.View}
        target={[
          Targets.Event,
          Targets.Concept,
          Targets.Idea,
          Targets.Series,
          Targets.Pipeline,
        ]}
        any
      >
        {isLargeScreen ? (
          <ActionMenu
            direction="right"
            trigger={
              <NavMenu.Item link={AppRoutes.Events}>Events</NavMenu.Item>
            }
          >
            <EventsTabMenuItems showIcons />
          </ActionMenu>
        ) : (
          <React.Fragment>
            <NavMenu.Item link={AppRoutes.Events}>Events</NavMenu.Item>
            <Menu.Menu className="submenu">
              <EventsTabMenuItems
                eventSubMenu={<EventSubMenu />}
                ideaSubMenu={<IdeaSubMenu />}
                seriesSubMenu={<SeriesSubMenu />}
                conceptSubMenu={<ConceptSubMenu />}
              />
            </Menu.Menu>
          </React.Fragment>
        )}
      </PermissionCheck>

      <PermissionCheck
        action={[Actions.View, Actions.Import]}
        target={[
          Targets.Contact,
          Targets.Organisation,
          Targets.ContactSearch,
          Targets.ContactSearchList,
          Targets.ContactSearchTag,
        ]}
        any
      >
        {isLargeScreen ? (
          <ActionMenu
            direction="right"
            trigger={
              <NavMenu.Item link={AppRoutes.Contacts}>Contacts</NavMenu.Item>
            }
          >
            <ContactsTabMenuItems showIcons />
          </ActionMenu>
        ) : (
          <React.Fragment>
            <NavMenu.Item link={AppRoutes.Contacts}>Contacts</NavMenu.Item>
            <Menu.Menu className="submenu">
              <ContactsTabMenuItems
                organisationSubMenu={<OrganisationSubMenu />}
              />
            </Menu.Menu>
          </React.Fragment>
        )}
      </PermissionCheck>

      <PermissionCheck action={Actions.View} target={[Targets.Campaign]} any>
        {isLargeScreen ? (
          <ActionMenu
            direction="right"
            trigger={
              <NavMenu.Item link={AppRoutes.Marketing}>Marketing</NavMenu.Item>
            }
          >
            <MarketingTabMenuItems showIcons />
          </ActionMenu>
        ) : (
          <React.Fragment>
            <NavMenu.Item link={AppRoutes.Marketing}>Marketing</NavMenu.Item>
            <Menu.Menu className="submenu">
              <MarketingTabMenuItems />
            </Menu.Menu>
          </React.Fragment>
        )}
      </PermissionCheck>

      <PermissionCheck action={Actions.View} target={Targets.Calendar}>
        <NavMenu.Item link={AppRoutes.Calendar}>Calendar</NavMenu.Item>
      </PermissionCheck>

      <PermissionCheck
        action={Actions.View}
        target={[
          Targets.Department,
          Targets.AllDepartment,
          Targets.PurchaseOrder,
          Targets.PurchaseOrderApprovalLevel,
          Targets.EventPurchaseOrderApprover,
        ]}
        any
      >
        {isLargeScreen ? (
          <ActionMenu
            direction="right"
            trigger={
              <NavMenu.Item link={AppRoutes.Finance}>Finance</NavMenu.Item>
            }
          >
            <FinanceTabMenuItems showIcons />
          </ActionMenu>
        ) : (
          <React.Fragment>
            <NavMenu.Item link={AppRoutes.Finance}>Finance</NavMenu.Item>
            <Menu.Menu className="submenu">
              <FinanceTabMenuItems />
            </Menu.Menu>
          </React.Fragment>
        )}
      </PermissionCheck>

      <PermissionCheck
        action={Actions.View}
        target={[
          Targets.EventsDashboard,
          Targets.ParticipantsDashboard,
          Targets.FinancialDashboard,
          Targets.BusinessDevelopmentDashboard,
          Targets.ImpactsAndEvidenceDashboard,
        ]}
        any
      >
        {isLargeScreen ? (
          <ActionMenu
            direction="right"
            trigger={
              <NavMenu.Item link={AppRoutes.Dashboard}>Dashboard</NavMenu.Item>
            }
          >
            <DashboardTabMenuItems showIcons />
          </ActionMenu>
        ) : (
          <React.Fragment>
            <NavMenu.Item link={AppRoutes.Dashboard}>Dashboard</NavMenu.Item>
            <Menu.Menu className="submenu">
              <DashboardTabMenuItems />
            </Menu.Menu>
          </React.Fragment>
        )}
      </PermissionCheck>

      <PermissionCheck
        action={Actions.View}
        target={[
          Targets.ParticipantType,
          Targets.ProgrammeTheme,
          Targets.OrganisationType,
          Targets.ProgrammeSubTheme,
          Targets.Site,
          Targets.EmailTemplate,
          Targets.User,
          Targets.Role,
          Targets.PendingUser,
        ]}
        any
      >
        {isLargeScreen ? (
          <ActionMenu
            direction="right"
            trigger={<NavMenu.Item link={AppRoutes.Admin}>Admin</NavMenu.Item>}
          >
            <AdminTabsMenuItems showIcons />
          </ActionMenu>
        ) : (
          <React.Fragment>
            <NavMenu.Item link={AppRoutes.Admin}>Admin</NavMenu.Item>
            <Menu.Menu className="submenu">
              <AdminTabsMenuItems />
            </Menu.Menu>
          </React.Fragment>
        )}
      </PermissionCheck>

      {isMobile && (
        <React.Fragment>
          <NavMenu.Item link={AccountRoutes.Profile}>
            My Public Profile
          </NavMenu.Item>
          <NavMenu.Item link={AccountRoutes.Privacy}>
            Privacy Settings
          </NavMenu.Item>
          <NavMenu.Item link={AppRoutes.Account} exact>
            My Account
          </NavMenu.Item>
          <NavMenu.Item link={SystemRoutes.Logout}>Log Out</NavMenu.Item>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <NavMenu vertical={false} className="app-nav-menu">
      <div className="app-nav-wrapper">
        {!isLargeScreen && (
          <React.Fragment>
            <div className="burger" onClick={() => setBurgerMenuOpen(true)}>
              <Icon name="sidebar" size="big" />
            </div>
            <SidePanel
              open={burgerMenuOpen}
              onClose={() => setBurgerMenuOpen(false)}
              onClosing={(e, d) =>
                !burgerMenuOpen ? true : canCloseSidePanel(e, d)
              }
              position="left"
              closeIcon
              className="burger-side-panel"
            >
              <NavMenu
                vertical
                className="burger-menu"
                onClick={(e) => {
                  if (
                    e.target?.className?.includes("nav-menu-item") ||
                    e.target?.parentNode?.className?.includes("nav-menu-item")
                  ) {
                    setBurgerMenuOpen(false);
                  }
                }}
              >
                {mainMenuItems}
              </NavMenu>
            </SidePanel>
          </React.Fragment>
        )}
        <Link to={AppRoutes.Root} className="logo">
          <Image src={Logo} alt="WiltonPark" />
        </Link>
        <div className="app-nav-items">{isLargeScreen && mainMenuItems}</div>
      </div>
      <div className="app-nav-account">
        <PermissionCheck action={Actions.View} target={Targets.GlobalSearch}>
          <GlobalSearchNavItem />
        </PermissionCheck>
        <AlertsNavItem />
        {!isMobile && <AccountNavItem />}
      </div>
    </NavMenu>
  );
};

export default WiltonParkMenu;
