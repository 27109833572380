import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { CampaignRoutes } from "constants/routes";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";

const CampaignMenuItems = ({ campaign }) => (
  <React.Fragment>
    <NavMenu.Item link={CampaignRoutes.Campaign(campaign.id)} exact>
      <span>Overview</span>
    </NavMenu.Item>
    <NavMenu.Item link={CampaignRoutes.Recipients(campaign.id)} exact>
      <span>Recipients{campaign.publishedUtc ? "" : " (Provisional)"}</span>
    </NavMenu.Item>
    <PermissionCheck action={Actions.View} target={Targets.CampaignNote}>
      <NavMenu.Item link={CampaignRoutes.Notes(campaign.id)} exact>
        <span>Notes</span>
      </NavMenu.Item>
    </PermissionCheck>
  </React.Fragment>
);

export default CampaignMenuItems;
