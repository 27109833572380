import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  connectForm,
  Field,
  FormUtils,
  FormValidators,
} from "@redriver/cinnamon";
import { Input, Popup } from "semantic-ui-react";
import { HexColorPicker } from "react-colorful";
import { ColourSquareIcon } from "components/icons";

const HEX_REGEX = RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");

const ColourPickerValidator = () => ({
  propTypes: {
    colourError: PropTypes.string,
  },
  defaultProps: {
    colourError: "Colour must be in hexadecimal format",
  },
  shouldValidate: () => true,
  getErrors: (field, value, props) => {
    if (value) {
      if (typeof value !== "string" || !HEX_REGEX.test(value)) {
        return [props.colourError];
      }
    }
    return [];
  },
});

const ColourPicker = ({
  value,
  onChange = () => {},
  errors,
  showErrors,
  allErrors,
  animateErrors,
  disabled,
  label = "",
  width,
  fluid,
  required,
  className,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Field
      required={required}
      disabled={disabled}
      width={width}
      fluid={fluid}
      label={label}
      errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
      animateErrors={animateErrors}
      className={classNames("colour-picker", className)}
    >
      <Popup
        on="click"
        position="bottom left"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        content={<HexColorPicker color={value} onChange={onChange} />}
        trigger={
          <Input
            value={value || ""}
            onChange={(_, { value }) => onChange(value)}
            disabled={disabled}
            icon={
              value ? (
                <ColourSquareIcon colour={value} className="icon" />
              ) : undefined
            }
          />
        }
      />
    </Field>
  );
};

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [FormValidators.requiredField(false), ColourPickerValidator()],
})(ColourPicker);
